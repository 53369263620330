<template>
  <div class="cabinet-aside">
    <div class="cabinet-aside__title">Мой AZ-MOST</div>
    <router-link
      :to="{ name: 'Materials' }"
      class="cabinet-aside__link mb-6"
      custom
      exact
      v-slot="{ href, isExactActive }"
    >
      <a
        :class="{ 'router-link-active': isExactActive }"
        :href="href"
         @click.prevent="ymEvent({ name: 'Materials' }, 'Материалы для вас')"
      >
        <div class="cabinet-aside__link-icon mr-2">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.74982 19.8947L4.74982 4.10526C4.74982 3.35677 5.35659 2.75 6.10508 2.75H13.9542C14.3196 2.75 14.6695 2.89754 14.9246 3.15915L15.4548 2.64217L14.9246 3.15915L19.8649 8.22614C20.1117 8.47924 20.2498 8.81875 20.2498 9.17225L20.2498 19.8947C20.2498 20.6432 19.643 21.25 18.8946 21.25H6.10508C5.35659 21.25 4.74982 20.6432 4.74982 19.8947Z"
              stroke="currentColor"
              :fill="
                isExactActive || $route.name === 'Materials'
                  ? 'currentColor'
                  : ''
              "
              stroke-width="1.5"
            />
            <path
              d="M19.5564 8.97574H15.1748C14.7668 8.9747 14.3758 8.81217 14.0874 8.52369C13.7989 8.23521 13.6364 7.84424 13.6353 7.43627L13.6353 3.05469"
              fill="white"
            />
            <path
              d="M19.5564 8.97574H15.1748C14.7668 8.9747 14.3758 8.81217 14.0874 8.52369C13.7989 8.23521 13.6364 7.84424 13.6353 7.43627L13.6353 3.05469"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div class="cabinet-aside__link-text">Материалы для вас</div>
      </a>
    </router-link>
    <router-link
      :to="{ name: 'Favorites' }"
      class="cabinet-aside__link mb-6"
      custom
      exact
      v-slot="{ href, isExactActive }"
    >
      <a
        :class="{ 'router-link-active': isExactActive }"
        :href="href"
        @click.prevent="ymEvent({ name: 'Favorites' }, 'Избранное')"
      >
        <div class="cabinet-aside__link-icon mr-2">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.00376 3C5.17533 3 4.50374 3.67157 4.50373 4.5L4.50342 21L12.0035 16.5L19.5034 21L19.5037 4.5C19.5037 3.67157 18.8322 3 18.0038 3H6.00376Z"
              stroke="currentColor"
              stroke-width="1.5"
              :fill="isExactActive ? 'currentColor' : ''"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div class="cabinet-aside__link-text">Избранное</div>
      </a>
    </router-link>
    <router-link
      :to="{ name: 'History' }"
      class="cabinet-aside__link mb-6"
      custom
      exact
      v-slot="{ href, isExactActive }"
    >
      <a
        :class="{ 'router-link-active': isExactActive }"
        :href="href"
       @click.prevent="ymEvent({ name: 'History' }, 'История просмотров')"
      >
        <div class="cabinet-aside__link-icon mr-2">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.7687 12.0018C21.7687 12.0018 17.4084 18.7551 12.0236 18.7592C6.63886 18.7633 2.26866 12.0166 2.26866 12.0166C2.26866 12.0166 6.62889 5.2633 12.0137 5.25922C17.3984 5.25514 21.7687 12.0018 21.7687 12.0018Z"
              stroke="currentColor"
              :fill="isExactActive ? 'currentColor' : ''"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <circle
              r="3.75"
              transform="matrix(1 -0.000757217 0.000738399 1 12.0184 12.0089)"
              stroke="currentColor"
              fill="white"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div class="cabinet-aside__link-text">История просмотров</div>
      </a>
    </router-link>
    <router-link
      :to="{ name: 'WatchLater' }"
      class="cabinet-aside__link mb-6"
      custom
      exact
      v-slot="{ href, isExactActive }"
    >
      <a
        :class="{ 'router-link-active': isExactActive }"
        :href="href"
         @click.prevent="ymEvent({ name: 'WatchLater' }, 'Смотреть позже')"
      >
        <div class="cabinet-aside__link-icon mr-2">
           <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="11.9961"
              cy="12"
              r="9"
              stroke="currentColor"
              :fill="isExactActive ? 'currentColor' : ''"
              stroke-width="1.5"
            />
            <path
              d="M11.5264 4.89844L11.5264 12.3199L15.5124 15.3787"
              :stroke="isExactActive ? 'white' : 'currentColor'"
              stroke-width="1.5"
            />
          </svg>
        </div>
        <div class="cabinet-aside__link-text">Смотреть позже</div>
      </a>
    </router-link>
    <router-link
      :to="{ name: 'Cabinet' }"
      class="cabinet-aside__link mb-12"
      custom
      v-slot="{ href, isExactActive }"
    >
      <a
        :class="{ 'router-link-active': isExactActive }"
        :href="href"
       @click.prevent="ymEvent({ name: 'Cabinet' }, 'Профиль')"
      >
        <div class="cabinet-aside__link-icon mr-2">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              r="3.75"
              transform="matrix(1 0 -1.88178e-05 1 12.0036 6.75)"
              stroke="currentColor"
              stroke-width="1.5"
              :fill="isExactActive ? 'currentColor' : ''"
              stroke-linecap="square"
            />
            <ellipse
              rx="6.75"
              ry="4.125"
              transform="matrix(1 0 -1.88178e-05 1 12.0033 16.875)"
              stroke="currentColor"
              stroke-width="1.5"
              :fill="isExactActive ? 'currentColor' : ''"
              stroke-linecap="square"
            />
          </svg>
        </div>
        <div class="cabinet-aside__link-text">Профиль</div>
      </a>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HeaderMyAz",
  methods: {
     ymEvent(to, title) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "LK left menu click", {
          "LK left menu click": {
            button: title,
            ...this.$root.ymFields
          },
        });
      }
      this.$router.push(to).catch(() => {});
    },
  }
};
</script>

<style lang="scss" scoped>
.cabinet-aside {
  &__title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #830051;

    margin-bottom: 24px;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: currentColor;

    &.router-link-active {
      color: #830051;
    }
  }
}
</style>