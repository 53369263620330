var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cabinet-aside"},[_c('div',{staticClass:"cabinet-aside__title"},[_vm._v("Мой AZ-MOST")]),_c('router-link',{staticClass:"cabinet-aside__link mb-6",attrs:{"to":{ name: 'Materials' },"custom":"","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isExactActive = ref.isExactActive;
return [_c('a',{class:{ 'router-link-active': isExactActive },attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.ymEvent({ name: 'Materials' }, 'Материалы для вас')}}},[_c('div',{staticClass:"cabinet-aside__link-icon mr-2"},[_c('svg',{attrs:{"width":"25","height":"24","viewBox":"0 0 25 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4.74982 19.8947L4.74982 4.10526C4.74982 3.35677 5.35659 2.75 6.10508 2.75H13.9542C14.3196 2.75 14.6695 2.89754 14.9246 3.15915L15.4548 2.64217L14.9246 3.15915L19.8649 8.22614C20.1117 8.47924 20.2498 8.81875 20.2498 9.17225L20.2498 19.8947C20.2498 20.6432 19.643 21.25 18.8946 21.25H6.10508C5.35659 21.25 4.74982 20.6432 4.74982 19.8947Z","stroke":"currentColor","fill":isExactActive || _vm.$route.name === 'Materials'
                ? 'currentColor'
                : '',"stroke-width":"1.5"}}),_c('path',{attrs:{"d":"M19.5564 8.97574H15.1748C14.7668 8.9747 14.3758 8.81217 14.0874 8.52369C13.7989 8.23521 13.6364 7.84424 13.6353 7.43627L13.6353 3.05469","fill":"white"}}),_c('path',{attrs:{"d":"M19.5564 8.97574H15.1748C14.7668 8.9747 14.3758 8.81217 14.0874 8.52369C13.7989 8.23521 13.6364 7.84424 13.6353 7.43627L13.6353 3.05469","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"round"}})])]),_c('div',{staticClass:"cabinet-aside__link-text"},[_vm._v("Материалы для вас")])])]}}])}),_c('router-link',{staticClass:"cabinet-aside__link mb-6",attrs:{"to":{ name: 'Favorites' },"custom":"","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:{ 'router-link-active': isExactActive },attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.ymEvent({ name: 'Favorites' }, 'Избранное')}}},[_c('div',{staticClass:"cabinet-aside__link-icon mr-2"},[_c('svg',{attrs:{"width":"25","height":"24","viewBox":"0 0 25 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M6.00376 3C5.17533 3 4.50374 3.67157 4.50373 4.5L4.50342 21L12.0035 16.5L19.5034 21L19.5037 4.5C19.5037 3.67157 18.8322 3 18.0038 3H6.00376Z","stroke":"currentColor","stroke-width":"1.5","fill":isExactActive ? 'currentColor' : '',"stroke-linecap":"square"}})])]),_c('div',{staticClass:"cabinet-aside__link-text"},[_vm._v("Избранное")])])]}}])}),_c('router-link',{staticClass:"cabinet-aside__link mb-6",attrs:{"to":{ name: 'History' },"custom":"","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:{ 'router-link-active': isExactActive },attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.ymEvent({ name: 'History' }, 'История просмотров')}}},[_c('div',{staticClass:"cabinet-aside__link-icon mr-2"},[_c('svg',{attrs:{"width":"25","height":"25","viewBox":"0 0 25 25","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M21.7687 12.0018C21.7687 12.0018 17.4084 18.7551 12.0236 18.7592C6.63886 18.7633 2.26866 12.0166 2.26866 12.0166C2.26866 12.0166 6.62889 5.2633 12.0137 5.25922C17.3984 5.25514 21.7687 12.0018 21.7687 12.0018Z","stroke":"currentColor","fill":isExactActive ? 'currentColor' : '',"stroke-width":"1.5","stroke-linecap":"square"}}),_c('circle',{attrs:{"r":"3.75","transform":"matrix(1 -0.000757217 0.000738399 1 12.0184 12.0089)","stroke":"currentColor","fill":"white","stroke-width":"1.5","stroke-linecap":"square"}})])]),_c('div',{staticClass:"cabinet-aside__link-text"},[_vm._v("История просмотров")])])]}}])}),_c('router-link',{staticClass:"cabinet-aside__link mb-6",attrs:{"to":{ name: 'WatchLater' },"custom":"","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:{ 'router-link-active': isExactActive },attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.ymEvent({ name: 'WatchLater' }, 'Смотреть позже')}}},[_c('div',{staticClass:"cabinet-aside__link-icon mr-2"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"11.9961","cy":"12","r":"9","stroke":"currentColor","fill":isExactActive ? 'currentColor' : '',"stroke-width":"1.5"}}),_c('path',{attrs:{"d":"M11.5264 4.89844L11.5264 12.3199L15.5124 15.3787","stroke":isExactActive ? 'white' : 'currentColor',"stroke-width":"1.5"}})])]),_c('div',{staticClass:"cabinet-aside__link-text"},[_vm._v("Смотреть позже")])])]}}])}),_c('router-link',{staticClass:"cabinet-aside__link mb-12",attrs:{"to":{ name: 'Cabinet' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:{ 'router-link-active': isExactActive },attrs:{"href":href},on:{"click":function($event){$event.preventDefault();return _vm.ymEvent({ name: 'Cabinet' }, 'Профиль')}}},[_c('div',{staticClass:"cabinet-aside__link-icon mr-2"},[_c('svg',{attrs:{"width":"25","height":"24","viewBox":"0 0 25 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"r":"3.75","transform":"matrix(1 0 -1.88178e-05 1 12.0036 6.75)","stroke":"currentColor","stroke-width":"1.5","fill":isExactActive ? 'currentColor' : '',"stroke-linecap":"square"}}),_c('ellipse',{attrs:{"rx":"6.75","ry":"4.125","transform":"matrix(1 0 -1.88178e-05 1 12.0033 16.875)","stroke":"currentColor","stroke-width":"1.5","fill":isExactActive ? 'currentColor' : '',"stroke-linecap":"square"}})])]),_c('div',{staticClass:"cabinet-aside__link-text"},[_vm._v("Профиль")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }